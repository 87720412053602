/**
 * {常量名、函数名}
 */
import Vue from 'vue';
import http from './http';
import axios from "axios";

import Router from 'vue-router'
/**
 * 接口封装类
 */
/**
 * 公共调用方法
 * @param opts
 * @returns {Promise<unknown>}
 */

/**
 * 帮助首页
 */

const getHelpIndexList = function (){
    const opts={
        url:"/api/office/help/index",
        params:{

        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};

/**
 * 帮助搜索结果页
 * @param keywords
 * @param pageNo
 * @returns {Promise<unknown>}
 */
const getHelpSearchList = function (keywords,pageNo){
    const opts={
        url:"/api/office/help/search/list",
        params:{
            keywords:keywords,
            pageNo:pageNo
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};

/**
 * 获取帮助详情
 * @param helpId
 * @returns {Promise<unknown>}
 */
const getHelpDetail = function (helpId){
    const opts={
        url:"/api/office/help/detail",
        params:{
            helpId:helpId
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 帮助列表
 * @param helpId
 * @returns {Promise<unknown>}
 */
const getHelpList = function (categoryId,pageNo){
    const opts={
        url:"/api/office/help/list",
        params:{
            categoryId:categoryId,
            pageNo:pageNo
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};

/**
 * 获取类目列表
 * @param categoryId
 * @returns {Promise<unknown>}
 */
const getHelpCategoryList = function (categoryId){
    const opts={
        url:"/api/office/help/category/list",
        params:{
            categoryId:categoryId,
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};

/**
 * 获取定制信息数据
 * @returns {Promise<unknown>}
 */
const getCustomizationInfo = function (){
    const opts={
        url:"/api/office/customization/info",
        params:{
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 发起定制
 * @param categoryId
 * @param productId
 * @param budGetId
 * @param remark
 * @param connectName
 * @param telephone
 * @returns {Promise<unknown>}
 */
const customization = function (categoryId,productId,budGetId,remark,connectName,telephone){
    const opts={
        url:"/api/office/customization",
        params:{
            categoryId:categoryId,
            productId:productId,
            budGetId:budGetId,
            remark:remark,
            connectName:connectName,
            telephone:telephone
        }
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 获取文档标题列表
 * @returns {Promise<unknown>}
 */
const getArticleSimpleList = function (){
    const opts={
        url:"/plat/api/office/article/simple/list",
        params:{
           
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl:'platform'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};


/**
 * 帮助文档详情
 * @returns {Promise<unknown>}
 */
const getOfficeArticleInfo = function (articleCode){
    const opts={
        url:"/plat/api/v3/office/article/info",
        params:{
            articleCode,
        },
       contentType: 'application/x-www-form-urlencoded',
        apiUrl:'platform'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 获取文档
 * @returns {Promise<unknown>}
 */
const getAppArticleList = function (keywords,parentArticleId){
    const opts={
        url:"/plat/api/office/article/list",
        params:{
            keywords,
            parentArticleId
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl:'platform'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 获取文档详情
 * @returns {Promise<unknown>}
 */
const getAppArticleInfo = function (articleId){
    const opts={
        url:"/plat/api/office/article/info",
        params:{
            articleId,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl:'platform'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
//联系我们
const apply = function (telephone,userName,company,remark,email) {
    const opts = {
        url: "/plat/api/experience/apply",
        params: {
            telephone,
            userName,
            company,
            remark,
            email
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl:'platform'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default function(Vue){
    //添加全局API
    Vue.prototype.$api = {

        getHelpIndexList,//获取帮助首页数据
        getHelpSearchList,//帮助搜索结果页
        getHelpDetail,//获取帮助详情页
        getHelpList,//获取帮助列表
        getHelpCategoryList,//获取帮助类目列表
        getCustomizationInfo,//获取定制数据
        customization,//发起定制

        getAppArticleList,
        getAppArticleInfo,
        apply,
        getArticleSimpleList,
        getOfficeArticleInfo,
    }
}




